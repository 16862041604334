<template>
    <div class="main-content">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-10 col-xl-8">
                    <!-- Header -->
                    <div class="header mt-md-5">
                        <div class="header-body">
                            <div class="row align-items-center">
                                <div class="col">

                                    <!-- Pretitle -->
                                    <h6 class="header-pretitle">
                                        New FAQ
                                    </h6>

                                    <!-- Title -->
                                    <h1 class="header-title">
                                        Create a new FAQ
                                    </h1>

                                </div>
                            </div> <!-- / .row -->
                        </div>
                    </div>

                    <!-- Form -->
                    <form class="mb-4">

                        <div class="form-group">

                            <!-- Label  -->
                            <label>
                                Subject
                            </label>

                            <el-select rules="required" v-model="postForm.subject.topic" placeholder="Select">
                                <el-option
                                        v-for="item in subjects"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </div>

                        <!-- Label  -->
                        <el-tabs v-model="activeTab">
                            <el-tab-pane label="EN" name="en">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.en.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.en.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="TR" name="tr">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.tr.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.tr.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="ES" name="es">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.es.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.es.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="PT" name="pt">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.pt.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.pt.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="FR" name="fr">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.fr.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.fr.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="DE" name="de">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.de.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.de.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="IT" name="it">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.it.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.it.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="JP" name="jp">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.jp.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.jp.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="KO" name="ko">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.ko.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.ko.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="RU" name="ru">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.ru.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.ru.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="AR" name="ar">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.ar.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.ar.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                        </el-tabs>

                        <!-- Divider -->
                        <hr class="mt-5 mb-5">

                        <!-- Buttons -->
                        <div style="text-align:right">
                            <el-button @click="saveData" type="primary">
                                Create FAQ
                            </el-button>
                            <el-button @click="goBack" type="warning">
                                Cancel
                            </el-button>
                        </div>

                    </form>

                </div>
            </div> <!-- / .row -->
        </div>
    </div>
</template>

<script>
  import { VueEditor } from 'vue2-editor'
  import { db } from '@/firebase'

  const defaultForm = {
    subject: {
      topic: '',
    },
    en: {
      question: '',
      answer: '',
    },
    jp: {
      question: '',
      answer: '',
    },
    ru: {
      question: '',
      answer: '',
    },
    fr: {
      question: '',
      answer: '',
    },
    de: {
      question: '',
      answer: '',
    },
    it: {
      question: '',
      answer: '',
    },
    es: {
      question: '',
      answer: '',
    },
    pt: {
      question: '',
      answer: '',
    },
    tr: {
      question: '',
      answer: '',
    },
    ko: {
      question: '',
      answer: '',
    },
    ar: {
      question: '',
      answer: '',
    },
  }

  export default {
    name: 'Create',
    components: {
      VueEditor,
    },
    data () {
      return {
        postForm: Object.assign({}, defaultForm),
        activeTab: 'en',
        subjects: [{
          value: 'about',
          label: 'About',
        }, {
          value: 'accessing-meditopia',
          label: 'Accessing Meditopia',
        }, {
          value: 'meditopia-guide',
          label: 'Meditopia Guide',
        }, {
          value: 'features',
          label: 'Features',
        }, {
          value: 'sharing-is-caring',
          label: 'Sharing is Caring',
        }, {
          value: 'gift-code',
          label: 'Gift Code',
        }, {
          value: 'cancellation',
          label: 'Cancellation',
        }, {
          value: 'refunds',
          label: 'Refunds',
        }, {
          value: 'technical-issues',
          label: 'Technical Issues',
        }, {
          value: 'membership',
          label: 'Membership',
        }, {
          value: 'fraud-issues',
          label: 'Fraud Issues',
        }, {
          value: 'contact-meditopia',
          label: 'Contact Meditopia',
        }],
      }
    },
    created () {
      this.postForm = Object.assign({}, defaultForm)
    },
    methods: {
      goBack () {
        this.$router.push({ path: '/list' })
      },
      saveData () {
        // const data = Object.values(this.postForm)
        // .map(item => ({ x: item.question, y: item.answer }))
        // .map(item => ({ x: item.x !== undefined ? item.x : 'subject', y: item.y !== undefined ? item.y : 'subject' }))
        // const emptyFields = data.filter(item => !item.x.length || !item.x.length)

        if (!this.postForm.subject.topic.length) {
          this.$message({
            message: 'Please Select a Subject!',
            type: 'error',
          })
        } else {
          db.collection('faq').add(this.postForm).then(() => {
            this.$message({
              message: 'Success!',
              type: 'success',
            })
            this.$router.push({ name: 'faq.list' })
          }).catch(() => {
            this.$message({
              message: 'Error! Try again!',
              type: 'error',
            })
          })
        }
      },
    },
  }
</script>

<style lang="scss" src="./CreateFAQ.scss"/>
