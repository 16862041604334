import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element'
import './assets/style/app.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import { VueEditor } from 'vue2-editor'
import { fireBase } from '@/firebase'
import { firestorePlugin } from 'vuefire'

Vue.use(firestorePlugin)
Vue.use(VueEditor)
Vue.config.productionTip = false

fireBase.auth().onAuthStateChanged(user => {
  store.dispatch('fetchUser', user)
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
