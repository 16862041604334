<template>
    <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
        <div class="container-fluid">

            <!-- Toggler -->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse"
                    aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <!-- Brand -->
            <router-link class="navbar-brand" :to="{ path: '/list' }">
                <img src="../../assets/img/logo.png" class="navbar-brand-img mx-auto" alt="Meditopia">
            </router-link>

            <!-- User (xs) -->
            <div class="navbar-user d-md-none">

                <!-- Dropdown -->
                <div class="dropdown">

                    <!-- Toggle -->
                    <a href="#" id="sidebarIcon" class="dropdown-toggle" role="button" data-toggle="dropdown"
                       aria-haspopup="true" aria-expanded="false">
                        <div class="avatar avatar-sm avatar-online">
                            <img src="assets/img/avatars/profiles/avatar-1.jpg" class="avatar-img rounded-circle"
                                 alt="...">
                        </div>
                    </a>

                    <!-- Menu -->
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="sidebarIcon">
                        <a href="profile-posts.html" class="dropdown-item">Profile</a>
                        <a href="account-general.html" class="dropdown-item">Settings</a>
                        <hr class="dropdown-divider">
                        <a href="sign-in.html" class="dropdown-item">Logout</a>
                    </div>

                </div>

            </div>

            <!-- Collapse -->
            <div class="collapse navbar-collapse" id="sidebarCollapse">

                <!-- Form -->
                <form class="mt-4 mb-3 d-md-none">
                    <div class="input-group input-group-rounded input-group-merge">
                        <input type="search" class="form-control form-control-rounded form-control-prepended"
                               placeholder="Search" aria-label="Search">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <span class="fe fe-search"></span>
                            </div>
                        </div>
                    </div>
                </form>

                <!-- Navigation -->
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link :to="{ name: 'faq.list' }" class="nav-link">
                            <i class="fe fe-list"></i> List FAQ
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'faq.create.faq' }" class="nav-link">
                            <i class="fe fe-plus"></i> Add New FAQ
                        </router-link>
                    </li>
                </ul>

                <!-- Divider -->
                <hr class="navbar-divider my-3">

                <el-button @click="logOut" type="error" >Log Out</el-button>

            </div> <!-- / .navbar-collapse -->

        </div>
    </nav>
</template>

<script>
  import { fireBase } from '@/firebase'

  export default {
    name: 'Sidebar',
    methods: {
      logOut () {
        fireBase
          .auth()
          .signOut()
          .then(() => {
            this.$router.replace({
              path: '/login',
            })
          })
      },
    },
  }
</script>

<style lang="scss" src="./Sidebar.scss"/>
