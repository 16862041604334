<template>
    <div class="home">
    </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Home',
    created () {
      if (!this.user.loggedIn) {
        this.$router.push({ path: '/login' })
      }
    },
    computed: {
      ...mapGetters({
        user: 'user',
      }),
    },
  }
</script>
