import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import {
  Button,
  Checkbox,
  CheckboxButton,
  Input,
  Loading,
  Message,
  MessageBox,
  Notification,
  Option,
  Radio,
  RadioButton,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableColumn,
  TabPane,
  Tabs,
  InfiniteScroll,
} from 'element-ui'
Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(Input)
Vue.use(Switch)
Vue.use(Checkbox)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Loading)
Vue.use(CheckboxButton)
Vue.use(Radio)
Vue.use(RadioButton)
Vue.use(RadioGroup)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(InfiniteScroll)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = (options) => {
  Notification(Object.assign({
    position: 'top-right',
    duration: 3000,
  }, options))
}
Vue.prototype.$message = Message
