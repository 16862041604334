import * as firebase from 'firebase'

const firebaseConfig = {
  apiKey: 'AIzaSyBOkun-ZvoOoFgTZpXnqLlAGk7IzzS5q0o',
  authDomain: 'meditopia-faq-admin-db.firebaseapp.com',
  databaseURL: 'https://meditopia-faq-admin-db.firebaseio.com',
  projectId: 'meditopia-faq-admin-db',
  storageBucket: 'meditopia-faq-admin-db.appspot.com',
  messagingSenderId: '215876568175',
  appId: '1:215876568175:web:abd19187b47caf9311399a',
}

const firebaseApp = firebase.initializeApp(firebaseConfig)

export const db = firebaseApp.firestore()
export const fireBase = firebaseApp
