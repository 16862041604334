<template>
    <div id="app">
        <sidebar v-if="$route.name !== 'Login'"></sidebar>
        <router-view/>
    </div>
</template>
<script>
  import Sidebar from './components/sidebar/Sidebar'

  export default {
    name: 'MeditopiaFAQApp',
    components: { Sidebar },
    data () {
      return {}
    },
  }
</script>
