<template>
    <div class="main-content" v-loading="loading">
        <div class="header">
            <div class="container-fluid">
                <div class="header-body">
                    <div class="row align-items-end">
                        <div class="col">
                            <h1 class="header-title">
                                FAQ List
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="list-page">
              <el-select @change="filterSubject" clearable rules="required" v-model="selectedSubject" placeholder="Select">
                <el-option
                    v-for="item in subjects"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
                <div class="card" style="margin-bottom:0 !important;margin-top:30px;">
                    <div class="card-body">
                        <el-table
                                :default-sort="{prop: 'data.subject.topic', order: 'ascending'}"
                                :data="list"
                                style="width: 100%;">
                            <el-table-column
                                    sortable
                                    prop="data.subject.topic"
                                    label="Subject"
                                    width="250">
                            </el-table-column>
                            <el-table-column
                                    label="Question">
                              <template slot-scope="scope">
                                {{getQuestionData(scope.row.data)}}
                              </template>
                            </el-table-column>
                            <el-table-column width="250">
                                <template slot-scope="scope">
                                    <router-link :to="{name: 'faq.edit', params: { id: scope.row.key }}">
                                        <el-button type="warning">Edit <i class="fe fe-edit view-icon"></i></el-button>
                                    </router-link>
                                    <el-button @click="deleteRow(scope.row.key)" style="margin-left:10px;"
                                               type="danger">Delete <i class="fe fe-delete view-icon"></i></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { db } from '@/firebase'

  export default {
    name: 'List',
    data () {
      return {
        list: [],
        sortedData: [],
        loading: false,
        selectedSubject: '',
        subjects: [{
          value: 'about',
          label: 'About',
        }, {
          value: 'accessing-meditopia',
          label: 'Accessing Meditopia',
        }, {
          value: 'meditopia-guide',
          label: 'Meditopia Guide',
        }, {
          value: 'features',
          label: 'Features',
        }, {
          value: 'sharing-is-caring',
          label: 'Sharing is Caring',
        }, {
          value: 'gift-code',
          label: 'Gift Code',
        }, {
          value: 'cancellation',
          label: 'Cancellation',
        }, {
          value: 'refunds',
          label: 'Refunds',
        }, {
          value: 'technical-issues',
          label: 'Technical Issues',
        }, {
          value: 'membership',
          label: 'Membership',
        }, {
          value: 'fraud-issues',
          label: 'Fraud Issues',
        }, {
          value: 'contact-meditopia',
          label: 'Contact Meditopia',
        }],
      }
    },
    created () {
      this.getList()
    },
    methods: {
      getList () {
        this.loading = true
        db.collection('faq').onSnapshot((snapshotChange) => {
          this.list = []
          snapshotChange.forEach((doc) => {
            this.list.push({
              key: doc.id,
              data: doc.data(),
            })
          })
          this.sortedData = this.list
        })
        this.loading = false
      },
      getQuestionData (data) {
        if (data.en.question) {
          return data.en.question
        } else {
          let sorted = Object.values(data)
          sorted = sorted.map(item => item.question)
          sorted = sorted.filter(item => item !== '')
          sorted = sorted.filter(item => item !== undefined)
          return sorted[0]
        }
      },
      filterSubject ($event) {
        if ($event !== '') {
          this.list = this.list.filter(item => item.data.subject.topic === $event)
          return this.list
        } else {
          this.list = this.sortedData
          return this.list
        }
      },
      deleteRow (id) {
        this.$confirm('This will permanently delete the data. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).then(() => {
          db.collection('faq').doc(id).delete().then(() => {
            this.$message({
              message: 'Deleted!',
              type: 'success',
            })
          })
            .catch(() => {
              this.$message({
                message: 'Error! Try again',
                type: 'error',
              })
            })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled',
          })
        })
      },
    },
  }
</script>

<style lang="scss" src="./List.scss"/>
