<template>
    <div class="main-content">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-10 col-xl-8">
                    <!-- Header -->
                    <div class="header mt-md-5">
                        <div class="header-body">
                            <div class="row align-items-center">
                                <div class="col">

                                    <!-- Pretitle -->
                                    <h6 class="header-pretitle">
                                        Edit FAQ
                                    </h6>

                                </div>
                            </div> <!-- / .row -->
                        </div>
                    </div>

                    <!-- Form -->
                    <form class="mb-4">

                        <div class="form-group">

                            <!-- Label  -->
                            <label>
                                Subject
                            </label>

                            <el-select v-model="postForm.subject" placeholder="Select">
                                <el-option
                                        v-for="item in subjects"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </div>

                        <!-- Label  -->
                        <el-tabs v-model="activeTab">
                            <el-tab-pane label="EN" name="en">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.en.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.en.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="TR" name="tr">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.tr.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.tr.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="ES" name="es">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.es.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.es.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="PT" name="pt">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.pt.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.pt.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="FR" name="fr">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.fr.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.fr.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="DE" name="de">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.de.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.de.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="IT" name="it">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.it.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.it.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="JP" name="jp">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.jp.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.jp.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="KO" name="ko">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.ko.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.ko.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="RU" name="ru">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.ru.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.ru.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="AR" name="ar">
                                <div class="form-group">
                                    <label>
                                        Question
                                    </label>
                                    <el-input v-model="postForm.ar.question" type="text"></el-input>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">
                                        Answer
                                    </label>
                                    <vue-editor v-model="postForm.ar.answer"></vue-editor>
                                </div>
                            </el-tab-pane>
                        </el-tabs>

                        <!-- Divider -->
                        <hr class="mt-5 mb-5">

                        <!-- Buttons -->
                        <div style="text-align:right;">
                            <el-button @click="editData" type="primary">
                                Edit FAQ
                            </el-button>
                            <router-link style="margin-left:10px;" :to="{ name: 'faq.list' }">
                                <el-button type="warning">
                                    Cancel
                                </el-button>
                            </router-link>
                            <el-button style="margin-left:10px;" @click="deleteData" type="danger">
                                Delete FAQ
                            </el-button>
                        </div>

                    </form>

                </div>
            </div> <!-- / .row -->
        </div>
    </div>
</template>

<script>
  import { VueEditor } from 'vue2-editor'
  import { db } from '@/firebase'

  const defaultForm = {
    subject: '',
    en: {
      question: '',
      answer: '',
      active: true,
    },
    jp: {
      question: '',
      answer: '',
      active: true,
    },
    ru: {
      question: '',
      answer: '',
      active: true,
    },
    fr: {
      question: '',
      answer: '',
      active: true,
    },
    de: {
      question: '',
      answer: '',
      active: true,
    },
    it: {
      question: '',
      answer: '',
      active: true,
    },
    es: {
      question: '',
      answer: '',
      active: true,
    },
    pt: {
      question: '',
      answer: '',
      active: true,
    },
    tr: {
      question: '',
      answer: '',
      active: true,
    },
    ko: {
      question: '',
      answer: '',
      active: true,
    },
    ar: {
      question: '',
      answer: '',
      active: true,
    },
  }

  export default {
    name: 'Edit',
    components: {
      VueEditor,
    },
    data () {
      return {
        id: '',
        postForm: Object.assign({}, defaultForm),
        activeTab: 'en',
        subjects: [{
          value: 'Access',
          label: 'Access',
        }, {
          value: 'Challenges & Badges',
          label: 'Challenges & Badges',
        }, {
          value: 'Gift Code',
          label: 'Gift Code',
        }, {
          value: 'How to Use Meditopia?',
          label: 'How to Use Meditopia?',
        }, {
          value: 'Refunds',
          label: 'Refunds',
        }, {
          value: 'Subscriptions',
          label: 'Subscriptions',
        }, {
          value: 'Membership Details',
          label: 'Membership Details',
        }, {
          value: 'Technical',
          label: 'Technical',
        }],
      }
    },
    created () {
      const dbRef = db.collection('faq').doc(this.$route.params.id)
      dbRef.get().then((doc) => {
        this.id = doc.id
        this.postForm = doc.data()
      }).catch((error) => {
        console.log(error)
      })
    },
    methods: {
      deleteData () {
        this.$confirm('This will permanently delete the data. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).then(() => {
          db.collection('faq').doc(this.$route.params.id).delete().then(() => {
            this.$message({
              message: 'Deleted!',
              type: 'success',
            })
            this.$router.push({ path: '/list' })
          })
            .catch(() => {
              this.$message({
                message: 'Error! Try again',
                type: 'error',
              })
            })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled',
          })
        })
      },
      editData () {
        db.collection('faq').doc(this.id)
          .update(this.postForm).then(() => {
            this.$message({
              message: 'Success!',
              type: 'success',
            })
            this.$router.push({ name: 'faq.list' })
          })
          .catch(() => {
            this.$message({
              message: 'Error! Try again!',
              type: 'error',
            })
          })
      },
    },
  }
</script>

<style lang="scss" src="./CreateFAQ.scss"/>
