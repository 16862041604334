<template>
    <div class="login">
        <div class="card" style="height: 100vh;margin-bottom:0;">
            <div style="width: 500px; margin:auto;">
                <div class="card-header" style="display:block;margin:auto; font-size: 32px; color:#fff;text-align:center;">Login</div>
                <div class="card-body" style="margin-bottom:0;">
                    <div v-if="error" class="alert alert-danger">{{error}}</div>
                    <form action="#" @submit.prevent="submit">
                        <div class="form-group row">
                            <label for="email" class="col-md-3 col-form-label">Email</label>

                            <div class="col-md-9">
                                <input
                                        id="email"
                                        type="email"
                                        class="form-control"
                                        name="email"
                                        value
                                        required
                                        autofocus
                                        v-model="form.email"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="password" class="col-md-3 col-form-label">Password</label>

                            <div class="col-md-9">
                                <input
                                        id="password"
                                        type="password"
                                        class="form-control"
                                        name="password"
                                        required
                                        v-model="form.password"
                                />
                            </div>
                        </div>

                        <div class="form-group row mb-0">
                            <div class="col-md-12 text-md-right">
                                <button type="submit" class="btn btn-primary">Login</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { fireBase } from '@/firebase'

  export default {
    name: 'Login',
    data () {
      return {
        form: {
          email: '',
          password: '',
        },
        error: null,
      }
    },
    methods: {
      submit () {
        fireBase
          .auth()
          .signInWithEmailAndPassword(this.form.email, this.form.password)
          .then(data => {
            this.$router.replace({ path: '/list' })
          })
          .catch(err => {
            this.error = err.message
          })
      },
    },
  }
</script>

<style lang="scss" src="./Login.scss"></style>
