import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import List from '../views/FAQ/List.vue'
import CreateFAQ from '../views/FAQ/CreateFAQ.vue'
import Edit from '../views/FAQ/Edit.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/list',
    name: 'faq.list',
    component: List,
  },
  {
    path: '/edit/:id',
    name: 'faq.edit',
    component: Edit,
  },
  {
    path: '/create-faq',
    name: 'faq.create.faq',
    component: CreateFAQ,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
